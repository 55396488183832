<script lang="ts" setup>
import type { TypeChartCompany } from "~/types/TypeChartCompany";

const props = defineProps<{
  values: NonNullable<TypeChartCompany["values"]>;
  height: string;
  bottom: string;
  drawBorder?: boolean;
}>();

const totalPercentage = computed(() => {
  let total = 0;
  props.values.forEach((item) => (total += calcHeight(item.from, item.to)));
  return total;
});

const calcHeight = (from: number, to: number) => {
  return Math.abs(to - from);
};

const reverseValues = computed(() => [...props.values].reverse());
</script>

<template>
  <div
    class="ci-bar absolute rounded-sm"
    :class="{ drawBorder }"
    :style="{ height, bottom }"
  >
    <div
      v-for="(value, v) in reverseValues"
      :key="v"
      class="ci-bar__item"
      :style="{
        background: value.colour,
        height:
          (calcHeight(value.from, value.to) / totalPercentage) * 100 + '%',
      }"
    >
      <NuxtIcon
        v-if="value.outlook"
        class="relative top-[-28px]"
        size="20"
        :name="`fairr:${value.outlook.toLowerCase()}`"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ci-bar {
  width: 50%;
  left: 25%;
  transition:
    height 0.4s ease,
    bottom 0.3s ease;

  &__item {
    transition: height 0.3s ease;
    max-width: 40px;
    margin: auto;

    &::before {
      content: "";
      color: rgb(255 255 255 / 50%);
      font-size: 1.25rem;
    }

    &:first-child {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }

    &:last-child {
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
    }
  }

  &.drawBorder {
    border: solid 1px #a1a0a0;
  }
}
</style>
